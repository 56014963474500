'use client';

import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import { useTextFieldValuesContext } from './context';
import styles from './styles.module.css';

interface Props {
  className?: string;
}

export function InputContainer({
  className,
  children,
}: PropsWithChildren<Props>) {
  const { disabled, size, errorMessage, isActive, shape } =
    useTextFieldValuesContext();
  const isErrorExist = errorMessage !== undefined && errorMessage !== '';

  return (
    <div
      className={clsx(
        styles.InputContainer,
        {
          [`${styles.small}`]: size === 'small',
          [`${styles.medium}`]: size === 'medium',
          [`${styles.large}`]: size === 'large',
        },
        {
          [`${styles.error}`]: isErrorExist,
          [`${styles.active}`]: isActive && !isErrorExist,
        },
        {
          [`${styles.disabled}`]: disabled,
        },
        {
          [`${styles.rect}`]: shape === 'rect',
          [`${styles.roundedFull}`]: shape === 'rounded-full',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

import * as React from 'react';
import { TEXT_FIELED_SHAPE } from '../constants';
import type {
  TextFieldType,
  TextFieldSizeType,
  TextFieldVariantType,
  TextFieldShapeType,
} from '../types';

export interface IContextValues {
  readonly value: string;
  readonly description?: string;
  readonly placeholder?: string;
  readonly size?: TextFieldSizeType;
  readonly disabled?: boolean;
  readonly errorMessage?: string | React.ReactElement;
  readonly type: TextFieldType;
  readonly isActive: boolean;
  readonly isFocused: boolean;
  readonly isPasswordShow?: boolean;
  readonly variant: TextFieldVariantType;
  readonly shape?: TextFieldShapeType;
  readonly tabIndex?: number;
}

export interface IContextActions {
  onChangeValue: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onEnter?: () => void;
  onClear?: () => void;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPasswordShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  value: '',
  size: 'medium',
  disabled: false,
  errorMessage: undefined,
  type: 'text',
  isActive: false,
  isFocused: false,
  isPasswordShow: false,
  variant: 'normal',
  shape: 'rect',
});

export const ActionsContext = React.createContext<IContextActions>({
  onChangeValue: () => undefined,
  onFocus: () => undefined,
  onEnter: () => undefined,
  onClear: () => undefined,
  onBlur: () => undefined,
  setIsActive: () => undefined,
  setIsFocused: () => undefined,
  setIsPasswordShow: () => undefined,
});

export interface Props {
  value: string;
  description?: string;
  placeholder?: string;
  type: TextFieldType;
  size: TextFieldSizeType;
  disabled: boolean;
  errorMessage?: string | React.ReactElement;
  variant: TextFieldVariantType;
  shape?: TextFieldShapeType;
  tabIndex?: number;
  onChangeValue: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onEnter?: () => void;
  onClear?: () => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export function Provider({
  value,
  description,
  placeholder,
  type,
  size,
  disabled,
  errorMessage,
  variant,
  shape = TEXT_FIELED_SHAPE.RECT,
  tabIndex,
  onChangeValue,
  onFocus,
  onEnter,
  onClear,
  onBlur,
  children,
}: React.PropsWithChildren<Props>) {
  const [isActive, setIsActive] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isPasswordShow, setIsPasswordShow] = React.useState(false);

  React.useEffect(() => {
    if (value !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [value]);

  const ctxValues = {
    value,
    description,
    placeholder,
    size,
    disabled,
    errorMessage,
    type,
    isActive,
    isFocused,
    isPasswordShow,
    variant,
    shape,
    tabIndex,
  };

  const ctxActions = {
    onChangeValue,
    onFocus,
    onEnter,
    onClear,
    onBlur,
    setIsActive,
    setIsFocused,
    setIsPasswordShow,
  };

  return (
    <ActionsContext value={ctxActions}>
      <ValuesContext value={ctxValues}>{children}</ValuesContext>
    </ActionsContext>
  );
}

export const useTextFieldValuesContext = () => React.useContext(ValuesContext);
export const useTextFieldActionsContext = () =>
  React.useContext(ActionsContext);

export const useTextFieldContext = () => {
  const values = useTextFieldValuesContext();
  const actions = useTextFieldActionsContext();

  return {
    ...values,
    ...actions,
  };
};

Provider.displayName = 'FilterContextProvider';

import { clsx } from 'clsx';
import { useTextFieldValuesContext } from './context';

import styles from './styles.module.css';

interface Props {
  className?: string;
}

export function Description({ className }: Props) {
  const { description, errorMessage } = useTextFieldValuesContext();

  if (!description || errorMessage) {
    return null;
  }

  return <p className={clsx(styles.Description, className)}>{description}</p>;
}

import { clsx } from 'clsx';
import { useTextFieldContext } from './context';
import * as React from 'react';

import styles from './styles.module.css';

interface Props {
  className?: string;
}

export function TextInput({ className }: Props) {
  const {
    value,
    placeholder,
    type,
    size,
    disabled,
    isPasswordShow,
    variant,
    isActive,
    isFocused,
    tabIndex,
    onChangeValue,
    onFocus,
    onEnter,
    onBlur,
    setIsFocused,
  } = useTextFieldContext();

  const [isPending, startTransition] = React.useTransition();
  /**
   * [2024-12-16, 강봉수]
   * 2byte 이상 기반의 텍스트 (한국어, 중국어 등) 입력은 글자가 최종 입력완료되는 단계가 있음.
   * 이를 처리하지 않으면 Enter 키 입력 시 이벤트 핸들러가 두번 호출되는 문제가 발생함.
   *
   * [처리 방법]
   * - onCompositionStart, onCompositionEnd 이벤트를 사용하여 입력 완료되었는지를 체크하는 로직을 추가
   * - useTransition을 사용하여 isComposition 상태를 변경하는 로직을 동기적으로 처리하여 isComposing 상태가 먼저 변경된 후,
   *   이벤트 핸들러가 호출되도록 함.
   */
  const [isComposing, setIsComposing] = React.useState(false);

  if (type === 'number') {
    return;
  }

  return (
    <input
      className={clsx(
        styles.Input,
        {
          [`${styles.activeM}`]:
            variant === 'floating-legend' &&
            size === 'medium' &&
            (isActive || isFocused),
          [`${styles.activeL}`]:
            variant === 'floating-legend' &&
            size === 'large' &&
            (isActive || isFocused),
        },
        className,
      )}
      type={type === 'password' && isPasswordShow ? 'text' : type}
      disabled={disabled}
      value={value}
      placeholder={variant === 'floating-legend' ? '' : placeholder}
      tabIndex={tabIndex}
      onChange={(e) => {
        const text = e.target.value;
        onChangeValue(text);
      }}
      onFocus={(e) => {
        onFocus && onFocus(e);
        setIsFocused(true);
      }}
      onBlur={(e) => {
        onBlur && onBlur(e);
        setIsFocused(false);
      }}
      onCompositionStart={() => startTransition(() => setIsComposing(true))}
      onCompositionEnd={() => startTransition(() => setIsComposing(false))}
      onKeyUp={(e) => {
        if (
          !isPending &&
          !isComposing &&
          (e.key === 'Enter' || e.keyCode === 13)
        ) {
          onEnter && onEnter();
        }
      }}
    />
  );
}

import * as TextFieldPrimitives from './primitives';

export function NumberTextField({
  type = 'text',
  value,
  onChangeValue,
  ...props
}: TextFieldPrimitives.RootProps) {
  const newValue = value.replace(/[^0-9-]/g, '');

  const onChangeNumber = (value: string) => {
    onChangeValue(value.replace(/[^0-9-]/g, ''));
  };

  return (
    <TextFieldPrimitives.Root
      {...props}
      value={newValue}
      onChangeValue={onChangeNumber}
      type={type}
    >
      <TextFieldPrimitives.InputContainer>
        <TextFieldPrimitives.TextInput />
        <TextFieldPrimitives.Meta>
          <TextFieldPrimitives.UpDownButton />
        </TextFieldPrimitives.Meta>
      </TextFieldPrimitives.InputContainer>
      <TextFieldPrimitives.Description />
      <TextFieldPrimitives.ErrorMessage />
    </TextFieldPrimitives.Root>
  );
}

export const TEXT_FIELED_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
} as const;

export const TEXT_FIELED_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

export const TEXT_FIELED_VARIANT = {
  NORMAL: 'normal',
  FLOATING_LEGEND: 'floating-legend',
} as const;

export const TEXT_FIELED_SHAPE = {
  RECT: 'rect',
  ROUNDED_FULL: 'rounded-full',
} as const;

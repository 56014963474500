import { clsx } from 'clsx';
import { useTextFieldValuesContext } from './context';

import styles from './styles.module.css';

interface Props {
  className?: string;
}

export function ErrorMessage({ className }: Props) {
  const { errorMessage } = useTextFieldValuesContext();

  if (!errorMessage) {
    return null;
  }

  return <p className={clsx(styles.ErrorMessage, className)}>{errorMessage}</p>;
}

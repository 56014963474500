'use client';

import { useTranslation } from './use-translation';

export interface Props {
  namespace?: string;
  i18nKey: string;
  options?: Record<string, unknown>;
}

export function I18nText({ namespace = 'common', i18nKey, options }: Props) {
  const { t } = useTranslation(namespace);

  return <>{t(i18nKey, options)}</>;
}

import { clsx } from 'clsx';
import { useTextFieldValuesContext } from './context';

import styles from './styles.module.css';

interface Props {
  label?: string;
}

export function Label({ label }: Props) {
  const { size, variant, isActive, isFocused } = useTextFieldValuesContext();

  return (
    label && (
      <p
        className={clsx(
          styles.Label,
          {
            [`${styles.default}`]: variant === 'normal',
            [`${styles.legendS}`]:
              variant === 'floating-legend' && size === 'small',
            [`${styles.legendM}`]:
              variant === 'floating-legend' && size === 'medium',
            [`${styles.legendL}`]:
              variant === 'floating-legend' && size === 'large',
          },
          {
            [`${styles.activeS}`]:
              variant === 'floating-legend' &&
              size === 'small' &&
              (isActive || isFocused),
            [`${styles.activeM}`]:
              variant === 'floating-legend' &&
              size === 'medium' &&
              (isActive || isFocused),
            [`${styles.activeL}`]:
              variant === 'floating-legend' &&
              size === 'large' &&
              (isActive || isFocused),
          },
        )}
      >
        {label}
      </p>
    )
  );
}

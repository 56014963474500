import * as TextFieldPrimitives from './primitives';

export function TextField({
  type = 'text',
  ...props
}: TextFieldPrimitives.RootProps) {
  return (
    <TextFieldPrimitives.Root {...props} type={type}>
      <TextFieldPrimitives.InputContainer>
        <TextFieldPrimitives.TextInput />
        <TextFieldPrimitives.Meta>
          <TextFieldPrimitives.ClearButton />
        </TextFieldPrimitives.Meta>
      </TextFieldPrimitives.InputContainer>
      <TextFieldPrimitives.Description />
      <TextFieldPrimitives.ErrorMessage />
    </TextFieldPrimitives.Root>
  );
}

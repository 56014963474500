'use client';

import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { Provider } from './context';
import { Label } from './label';
import {
  TEXT_FIELED_TYPE,
  TEXT_FIELED_SHAPE,
  TEXT_FIELED_SIZE,
  TEXT_FIELED_VARIANT,
} from '../constants';
import type {
  TextFieldSizeType,
  TextFieldShapeType,
  TextFieldVariantType,
  TextFieldType,
} from '../types';

import styles from './styles.module.css';

export interface Props {
  className?: string;
  value: string;
  placeholder?: string;
  description?: string;
  type?: TextFieldType;
  label?: string;
  variant?: TextFieldVariantType;
  size?: TextFieldSizeType;
  shape?: TextFieldShapeType;
  disabled?: boolean;
  errorMessage?: string | React.ReactElement;
  tabIndex?: number;
  onChangeValue: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onEnter?: () => void;
  onClear?: () => void;
}

export function Root({
  className,
  value,
  placeholder,
  type = TEXT_FIELED_TYPE.TEXT,
  label,
  description,
  variant = TEXT_FIELED_VARIANT.NORMAL,
  size = TEXT_FIELED_SIZE.MEDIUM,
  shape = TEXT_FIELED_SHAPE.RECT,
  disabled = false,
  errorMessage,
  tabIndex,
  onChangeValue,
  onFocus,
  onEnter,
  onClear,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Provider
      value={value}
      description={description}
      placeholder={placeholder}
      type={type}
      size={size}
      shape={shape}
      disabled={disabled}
      errorMessage={errorMessage}
      variant={label && label.length > 0 ? variant : 'normal'}
      onChangeValue={onChangeValue}
      onFocus={onFocus}
      onEnter={onEnter}
      onClear={onClear}
      tabIndex={tabIndex}
    >
      <div
        className={clsx(
          styles.Root,
          {
            [`${styles.small}`]: size === 'small',
            [`${styles.medium}`]: size === 'medium',
            [`${styles.large}`]: size === 'large',
          },
          className,
        )}
      >
        <Label label={label} />
        {children}
      </div>
    </Provider>
  );
}

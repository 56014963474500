import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useTextFieldContext } from './context';

import styles from './styles.module.css';

export function PasswordShowButton() {
  const { disabled, type, isPasswordShow, setIsPasswordShow } =
    useTextFieldContext();

  if (type !== 'password') {
    return;
  }

  return (
    <button
      className={styles.PasswordShowButton}
      disabled={disabled}
      onClick={() => setIsPasswordShow(!isPasswordShow)}
    >
      {isPasswordShow ? (
        <IconEye stroke={1.5} size={18} />
      ) : (
        <IconEyeOff stroke={1.5} size={18} />
      )}
    </button>
  );
}

import { clsx } from 'clsx';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useTextFieldContext } from './context';

export function UpDownButton() {
  const { value, disabled, onChangeValue } = useTextFieldContext();

  const handleUp = () => {
    onChangeValue(String(Number(value) + 1));
  };

  const handleDown = () => {
    onChangeValue(String(Number(value) - 1));
  };

  return (
    <div
      className={clsx('flex flex-col h-[24px] w-[12px]', {
        'text-[#1C1C1C]': !disabled,
        'text-[#C5C7CA]': disabled,
      })}
    >
      <button
        className="size-[12px] hover:bg-[#F5F6F8] rounded-[4px]"
        onClick={handleUp}
      >
        <IconChevronUp size={12} />
      </button>
      <button
        className="size-[12px] hover:bg-[#F5F6F8] rounded-[4px]"
        onClick={handleDown}
      >
        <IconChevronDown size={12} rotate={180} />
      </button>
    </div>
  );
}

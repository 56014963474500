import { ClearIcon } from '@dop-ui/icons/react/dop/24';
import { useTextFieldContext } from './context';

import styles from './styles.module.css';

export function ClearButton() {
  const { disabled, isActive, onChangeValue, onClear } = useTextFieldContext();
  const clickHandler = () => {
    onChangeValue('');
    onClear && onClear();
  };

  return (
    isActive && (
      <button
        className={styles.ClearButton}
        onClick={clickHandler}
        disabled={disabled}
      >
        <ClearIcon size={20} />
      </button>
    )
  );
}
